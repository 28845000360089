
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import AppService, {
  refreshSelectMedia,
  getConfigs,
  renderImageUrl,
  showMultipleMedia,
  showSingleMedia,
  setConfigs,
} from "@/core/services/AppService";
import { Actions } from "@/store/enums/StoreEnums";
import { default_image } from "@/core/config/AppConfig";
import { BannersMiddleItem, HomeConfigs } from "@/store/model/Config";
import { AdminDefault, image, SeoDefault } from "@/store/model";

export default defineComponent({
  name: "edit_experience",
  props: ["id"],
  setup(props) {
    const image_editor = ref();
    const image_news = ref();
    const store = useStore();
    const key = "home_configs";
    const router = useRouter();
    const loading = ref<boolean>(false);
    let objectData = ref<HomeConfigs>({
      banners_top: [],
      banners_middle: [],
      seo: {
        robot: "",
        title: "",
        thumbnail: default_image,
        description: "",
      },
    });
    const data_home = ref({});
    onMounted(async () => {
      setCurrentPageBreadcrumbs("Cấu hình Website", ["Cấu hình"]);
      MenuComponent.reinitialization();

      // await store.dispatch("GET_EXPERIENCE_DETAIL", props.id);
      // await store.dispatch(Actions.GET_ALL_PROVINCE);
      // objectData.value = await store.getters["currentExperience"];
      getConfigs(key).then((data) => {
        if (typeof data === "object")
          objectData.value = { ...objectData.value, ...data };
      });
      // setConfigs({key:"home_config",value:objectData.value})
    });
    const media_actions = ref([
      "banners_top",
      "banners_middle",
      "seo.thumbnail",
    ]);
    const addContent = () => {
      objectData.value.banners_middle.push({
        url_youtube: "",
        image: default_image,
      } as BannersMiddleItem);
      media_actions.value.push(
        "banners_middle." +
          (objectData.value.banners_middle.length - 1).toString() +
          ".image"
      );
    };

    const removeContent = (index) => {
      if (index > -1) {
        objectData.value.banners_middle.splice(index, 1);
      }
    };

    const formRef = ref<null | HTMLFormElement>(null);
    const submit = () => {
      setConfigs({ key, value: objectData.value })
        .then(() => {
          Swal.fire({
            text: "Cập nhật thông dự án thành công",
            icon: "success",
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Danh sách",
            cancelButtonText: "Tiếp tục chỉnh sửa",
            customClass: {
              confirmButton: "btn fw-bold btn-light-success",
              cancelButton: "btn fw-bold btn-light-primary",
            },
          });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Thử lại!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };
    const isMultipleMedia = computed(() => store.getters["getIsMultiple"]);

    watch(
      () => store.state.MediaModule.actionCallback,
      (action, second) => {
        //console.log(action);
        if (media_actions.value.includes(action)) {
          let fields = action.split(".");
          //console.log(fields.length);
          switch (fields.length) {
            case 1:
              if (isMultipleMedia.value) {
                objectData.value[action] = store.getters["getSelectMultiMedia"];
              } else {
                objectData.value[action] = store.getters["getSelectMedia"];
              }
              break;
            case 2:
              if (isMultipleMedia.value) {
                objectData.value[fields[0]][fields[1]] =
                  store.getters["getSelectMultiMedia"];
              } else {
                objectData.value[fields[0]][fields[1]] =
                  store.getters["getSelectMedia"];
              }
              break;
            case 3:
              //console.log(objectData.value[fields[0]][fields[1]][fields[2]]);
              if (isMultipleMedia.value) {
                objectData.value[fields[0]][fields[1]][fields[2]] =
                  store.getters["getSelectMultiMedia"];
              } else {
                objectData.value[fields[0]][fields[1]][fields[2]] =
                  store.getters["getSelectMedia"];
              }
              break;
            default:
              break;
          }

          refreshSelectMedia();
        }
      }
    );
    const removeImage = (action) => {
      let fields = action.split(".");
      if (fields.length > 1) {
        objectData.value[fields[0]][fields[1]] = default_image;
      } else {
        objectData.value[action] = default_image;
      }
    };
    const removeImages = (action) => {
      let fields = action.name.split(".");
      if (fields.length > 1) {
        objectData.value[fields[0]][fields[1]].splice(action.index, 1);
      } else {
        objectData.value[fields[0]].splice(action.index, 1);
      }
    };
    return {
      submit,
      renderImageUrl,
      showSingleMedia,
      showMultipleMedia,
      loading,
      objectData,
      image_editor,
      image_news,
      media_actions,
      removeImage,
      removeImages,
      addContent,
      removeContent,
    };
  },
});
